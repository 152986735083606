<template>
  <b-card>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Menu arsip terdiri dari berbagai macam kategori, termasuk diantaranya </span>
    </p>
    <ul
      class="c8 lst-kix_wyt7z0egu74m-0 start"
      start="1"
    >
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c2">Surat Masuk</span>
      </li>
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c2">Perintah</span>
      </li>
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c2">Surat Keluar</span>
      </li>
    </ul>
    <p class="c3">
      <span class="c2">3 menu tersebut akan otomatis masuk ke menu arsip ketika statusnya sudah close, sebagai mana ilustrasi berikut.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 280.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image10.png')"
          style="width: 601.70px; height: 280.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c3">
      <span>untuk kategori selain itu maka pengguna dapat menambah arsip melalui menu </span><span class="c1">tambah arsip</span>
    </p>
    <p class="c0">
      <span class="c1" />
    </p>
    <p class="c3">
      <span class="c2">berikut contoh tampilan menu tambah arsip, pengguna harus melengkapi field yang tersedia sebelum memilih tombol simpan.</span>
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 300.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image24.png')"
          style="width: 601.70px; height: 300.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Setelah menambahkan data maka arsip akan tersimpan sesuai kategori yang dipilih, misalnya sebagai berikut.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 206.67px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image14.png')"
          style="width: 601.70px; height: 206.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Untuk melihat detail dari arsip tersebut, dapat memilih link pada no. arsip atau opsi edit pada kolom aksi, dan akan muncul tampilan sebagai berikut</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 300.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image7.png')"
          style="width: 601.70px; height: 300.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">berikut merupakan detail arsip yang telah dipilih, untuk fitur edit dokumen arsip serta penggantian status arsip dari open menjadi close hanya dapat dilakukan oleh staff SE, sebagaimana berikut</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 121.33px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image17.png')"
          style="width: 601.70px; height: 121.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Tombol simpan tidak dapat dipilih jika pengguna yang login bukan staff SE</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>
